@import '../stylesheet';

/* START THEME
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_logo {
  // never more then width: 200px and height 60px
  svg.main-logo-carnedelmercado {width: 200px; height: 33px;}
  svg.main-logo-operacionlimpieza {width: 180px; height: 40px;}
  svg.main-logo-tuvenganza {width: 200px; height: 50px;}
  svg.main-logo-herbigass {width: 200px; height: 33px;}
  svg.main-logo-chicasloca {width: 200px; height: 33px;}
}

.module-footer {
  svg.main-logo-mamacitaz {width: 160px; height: 40px;}
  svg.main-logo-operacionlimpieza {width: 160px; height: 40px;}
}

// RESPONSIVE - CUSTOM
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media screen and (min-width: 1000px) and (max-width: 1048px) {
  .module-header_logo svg.main-logo {width: 160px !important;}
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  .module-header_logo {
    // never more then width: 170px and height 35px
    svg.main-logo-carnedelmercado {width: 170px; height: 28px;}
    svg.main-logo-operacionlimpieza {width: 155px; height: 35px;}
    svg.main-logo-tuvenganza {width: 150px; height: 35px;}
    svg.main-logo-herbigass {width: 170px; height: 28px;}
    svg.main-logo-chicasloca {width: 170px; height: 28px;}
  }
}

// RESPONSIVE - Mobile Small
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile_small} {
  .module-header_logo {
    // never more then width: 125px and height 35px
    svg.main-logo-carnedelmercado {width: 125px; height: 21px;}
    svg.main-logo-operacionlimpieza {width: 125px; height: 30px;}
    svg.main-logo-tuvenganza {width: 110px; height: 25px;}
    svg.main-logo-herbigass {width: 125px; height: 21px;}
    svg.main-logo-chicasloca {width: 125px; height: 21px;}
  }
}








